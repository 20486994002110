import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getRefurbishedPayment,
  refurbishedPaymentListSuccessful,
  getRefurbishedByID,
  refurbishedByIDSuccessful,
  refurbishedAddSuccessful,
  isModelVisible,

  isRepairAmountViewModelVisible,
  repairTypeImageUploadSuccessful,
  repairTypesSuccessful,
  pendingRepairAmountListSuccessful,
  getPendingRepairAmountList,
  repairTypeImageDocUploadSuccessful,
  estimationAmountSuccessful,
} from "./refurbishedSlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData,
} from "../../../helpers/Helper";
import moment from "moment";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* RefurbishedPaymentList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/accounts/refurbishedamount/", params);
    yield put(refurbishedPaymentListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddRefurbishedPayment({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/accounts/refurbishedamount/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Refurbished Payment Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getRefurbishedPayment({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Refurbished Payment. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(refurbishedAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/accounts/refurbishedamount/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Refurbished Payment Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getRefurbishedPayment({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Refurbished Payment. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(refurbishedAddSuccessful(null));
    }
  } catch (error) {
    if(error.response.status === 400){
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    }else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
  }
}

function* DeleteRefurbishedPayment({ payload }) {
  var areaID = payload;
  try {
    const response = yield call( postDelete, "/accounts/refurbishedamount/" + areaID);
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Refurbished Payment has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getRefurbishedPayment({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Refurbished Payment has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* RefurbishedPaymentByID({ payload }) {
  try {
    const response = yield call(
      getList,
      `/accounts/refurbishedamount/${payload.id}`,
      {}
    );
    yield put(refurbishedByIDSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* RepairTypeImageDocUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      repairTypeImageDocUploadSuccessful({
        response,
        uid: payload.uid,
        ImageType: payload.imageType,
        name: payload?.name,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* RepairTypeImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      repairTypeImageUploadSuccessful({
        response,
        uid: payload.file.uid,
        ImageType: payload.imageType,
        name: payload.file.name,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* RepairType({ payload }) {
  try {
    const response = yield call(getList, "/masters/mini/repairs/", {});
    yield put(repairTypesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* UpdateFsyncStatus({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/accounts/refurbishedreapprove/" + payload.id,
      { refurbishedapprovalstatus: payload.refurbishedapprovalstatus }
    );
    if (response.status === 200) {
      if (response.data.focus_post == true) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Selected Refurbished Payment is successfully synchronised into focus
              </p>
            </div>
          ),
        });
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected Refurbished Payment is not posted on focus. please try
                again!
              </p>
            </div>
          ),
        });
      }

      yield put(getRefurbishedPayment({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Refurbished Payment is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* PendingRepairAmountList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairspendingstatuslist",
      params
    );
    yield put(pendingRepairAmountListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* RepairAmountApprove({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/accounts/refurbishedapprove/" + payload.id,
      payload.repairAmountObj
    );
    if (response.status === 200) {
      if (payload.repairAmountObj.refurbishedapprovalstatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Refurbished Payment has been Approved Successfully!
              </p>
            </div>
          ),
        });
      } else if (payload.repairAmountObj.refurbishedapprovalstatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Refurbished Payment has been Rejected Successfully!
              </p>
            </div>
          ),
        });
      }
      yield put(getRefurbishedPayment({ page: 1, search: "", page_size: 10 }));
      yield put(isRepairAmountViewModelVisible(false));
    } else {
      if (payload.repairAmountObj.refurbishedapprovalstatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Refurbished Payment has been not Approved !
              </p>
            </div>
          ),
        });
      } else if (payload.repairAmountObj.refurbishedapprovalstatus == 3) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Refurbished Payment has been not Rejected!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* EstimationAmount({ payload }) {
  try {
    const response = yield call(
      getList,
      `/accounts/get_estimation_amount/${payload.id}`,
      {}
    );
    yield put(estimationAmountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchGetRefurbishedPayment() {
  yield takeEvery("refurbished/getRefurbishedPayment", RefurbishedPaymentList);
}
export function* watchRefurbishedAdd() {
  yield takeEvery("refurbished/refurbishedAdd", AddRefurbishedPayment);
}
export function* watchRefurbishedDelete() {
  yield takeEvery("refurbished/refurbishedDelete", DeleteRefurbishedPayment);
}
export function* watchGetRefurbishedByID() {
  yield takeEvery("refurbished/getRefurbishedByID", RefurbishedPaymentByID);
}
export function* watchGetRepairTypeImageUpload() {
  yield takeEvery( "refurbished/getRepairTypeImageUpload",RepairTypeImageUpload);
}
export function* watchGetRepairTypes() {
  yield takeEvery("refurbished/getRepairTypes", RepairType);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("refurbished/fsyncStatusUpdate", UpdateFsyncStatus);
}

export function* watchGetRepairTypeImageDocUplod() {
  yield takeEvery("refurbished/getRepairTypeImageDocUpload",RepairTypeImageDocUpload);
}
export function* watchGetEstimationAmount() {
  yield takeEvery("refurbished/getEstimationAmount", EstimationAmount);
}



export function* watchRepairAmountApproveStatus() {
  yield takeEvery("refurbished/repairAmountApproveStatus", RepairAmountApprove);
}
export function* watchGetPendingRepairAmountList() {
  yield takeEvery( "refurbished/getPendingRepairAmountList",PendingRepairAmountList);
}

function* refurbishedSaga() {
  yield all([
    fork(watchGetRefurbishedPayment),
    fork(watchRefurbishedAdd),
    fork(watchRefurbishedDelete),
    fork(watchGetRefurbishedByID),
    fork(watchGetRepairTypeImageUpload),
    fork(watchGetRepairTypes),
    fork(watchFsyncStatusUpdate),
    fork(watchGetRepairTypeImageDocUplod),
    fork(watchGetEstimationAmount),
    
    fork(watchGetPendingRepairAmountList),
    fork(watchRepairAmountApproveStatus),
  ]);
}

export default refurbishedSaga;
