import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Divider } from "antd";
const { Text, Link } = Typography;
import { People } from "react-iconly";
import Chart from "react-apexcharts";

function EnquiryCard(props) {
  // const {data} = props;

  const options = useMemo(() => {
    return {
      series: props.data?.map((d) => d.total),
      chart: {
        id: "earnings-donut-card",
        fontFamily: "Manrope, sans-serif",
        type: "donut",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        colors: props.data?.map((d) => d.color),
        labels: props.data?.map((d) => d.title + ":" + d.total),
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "90%",
              labels: {
                show: true,
                name: {
                  fontSize: "2rem",
                },
                value: {
                  fontSize: "20px",
                  fontWeight: "regular",
                  color: "B2BEC3",
                  formatter(val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  fontSize: "16px",
                  fontWeight: "bold",
                  label: "Total",
                  color: "#636E72",
                  formatter: function (w) {
                    return `${w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0)}`;
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              legend: {
                itemMargin: {
                  horizontal: 18,
                  vertical: 8,
                },
              },
            },
          },
        ],
        legend: {
          itemMargin: {
            horizontal: 6,
            vertical: 4,
          },
          horizontalAlign: "center",
          position: "left",
          fontSize: "14px",
          inverseOrder: true,
          markers: {
            radius: 16,
          },
        },

      },
    }
  }, [props]);
  return (
    <>
      <Card className="hp-border-color-black-40 hp-card-6">
        <Row>
          <Col span={24}>
            <Text className="h6 hp-d-block" strong>
              {props.title}
            </Text>
          </Col>

          <Col span={24}>
            <div id="earnings-donut-card" className="hp-donut-chart">
              <Chart
                options={options?.
                  chart
                }
                series={options?.
                  series}
                type="donut"
                height={150}
                legend="legend"
              />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
function mapStateToProps({ }) {
  return {};
}

export default connect(mapStateToProps, {})(EnquiryCard);
