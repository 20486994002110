// layout
import customiseReducer from "./customise/customiseSlice";

// Authentication Module
import Login from "./auth/login/loginSlice";

//notification
import Notification from "./notifications/notificationSlice";

// mini
import Mini from "./mini/miniSlice";
// Reports
import Reports from "./reports/reportsSlice";
// system APIs
import System from "./system/systemSlice";
//Masters
import Country from "./Masters/Country/countrySlice";
import State from "./Masters/State/stateSlice";
import District from "./Masters/District/districtSlice";
import City from "./Masters/City/citySlice";
import Area from "./Masters/Area/areaSlice";
import Zones from "./Masters/Zones/zonesSlice";
import Firm from "./Masters/Firm/firmSlice";
import Person from "./Masters/Person/personSlice";
import Brands from "./Masters/Brand/brandSlice";
import Emissionnorms from "./Masters/Emissionnorms/emissionnormsSlice";
import Occupations from "./Masters/Occupations/occupationsSlice";
import Colors from "./Masters/Colors/colorsSlice";
import Banks from "./Masters/Banks/banksSlice";
import Priority from "./Masters/Priority/prioritySlice";
import Companybanks from "./Masters/Companybanks/companybanksSlice";
import Divisions from "./Masters/Divisions/divisionsSlice";
import Clusters from "./Masters/Clusters/clustersSlice";
import Mechanics from "./Masters/Mechanics/mechanicsSlice";
import Dealers from "./Masters/Dealers/dealersSlice";
import Brandfamily from "./Masters/Brandfamily/brandfamilySlice";
import Models from "./Masters/Models/modelsSlice";
import Addressproof from "./Masters/Addressproof/addressproofSlice";
import Emidates from "./Masters/Emidates​/emidates​Slice";
import Insurance from "./Masters/Insurance/insuranceSlice";
import Leavereasons from "./Masters/Leavereasons/leavereasonsSlice";
import Versionfeatures from "./Masters/Versionfeatures​/versionfeatures​Slice";
import Rejectreasons from "./Masters/Rejectreasons/rejectreasonsSlice";
import Formulas from "./Masters/Formulas/formulaSlice";
import Departments from "./Masters/Departments/departmentSlice";
import ReEstimation from "./Masters/ReEstimation/reEstimationSlice";
import RepairAmount from "./Masters/RepairAmount/repairAmountSlice";
import FvQuestion from "./Masters/FvQuestions/fvQuestionSlice";
import Vehicletypes from "./Masters/Vehicletypes/vehicletypesSlice";
import Showrooms from "./Masters/Showrooms/showroomsSlice";
import Percentageinterests from "./Masters/Percentageinterests/percentageinterestsSlice";
import Financiers from "./Masters/Financiers/financiersSlice";
import Versions from "./Masters/Versions/versionsSlice";
import Counters from "./Masters/Counters/counterSlice";
import Vehicles from "./Masters/Vehicles/vehiclesSlice";
import Salequotationlist from "./Masters/Salequotationlist/salequotationlistSlice";
import Tenures from "./Masters/Tenures/tenuresSlice";
import RepairTypes from "./Masters/RepairTypes/repairTypesSlice";
import BannersAds from "./Masters/BannersAds/bannersAdSlice";
import Asset from "./Masters/Assets/assetSlice";
import Organization from "./Masters/HashCloudOrganization/organizationSlice";
import DailyExpense from "./Masters/DailyExpenses/dailyExpenseSlice";
import Category from "./Masters/Category/categorySlice";
import SubCategory from "./Masters/SubCategory/subCategorySlice";
import Items from "./Masters/Items/itemSlice";
import ServiceMech from "./Masters/ServiceMechanic/serviceMechSlice";
import Ramp from "./Masters/Ramp/rampSlice";

//userProfile
import UserProfile from "./userProfile/profileSlice";

//Setting
import ManageUsers from "./settings/ManageUsers/manageUserSlice";
import ManageProfile from "./settings/ManageProfile/profileSlice";
import LocationManager from "./settings/LocationManager/locationManagerSlice";
import Managers from "./settings/Managers/ManagersSlice";
import Approval from "./settings/Approval/approvalSlice";
import ImportExport from "./settings/ImportExport/importExportSlice";
import BackupDatabase from "./settings/BackupDatabse/backupDataBaseSlice";
import ManageCompany from "./settings/ManageCompany/manageCompanySlice";
import AppSettings from "./settings/AppSettings/appsettingsSlice";

//Side Menu
import Purchase from "./sideMenu/purchases/purchasesSlice";
import CounterSales from "./sideMenu/vehicleCounterSales/vehicleCounterSalesSlice";
import Enquiry from "./sideMenu/Enquiry/enquirySlice";
import StockRequest from "./sideMenu/StockTransfer/StockRequest/stockrequestSlice";
import NewInsurance from "./sideMenu/newInsurance/newInsuranceSlice";
import Rto from "./sideMenu/rto/rtoSlice";
import TransactionApprove from "./sideMenu/transactionApprove/transactionApproveSlice";
import Stock from "./sideMenu/stock/stockSlice";
import MyStock from "./sideMenu/mystock/mystockSlice";
import StockPrice from "./sideMenu/stockPriceView/stockPriceSlice";
import Target from "./sideMenu/targets/targetSlice";
import Dashboard from "./dashboard/dashboardSlice";
import CustomerDefaulter from "./sideMenu/customerDefaulter/customerDefaulterSlice";
import RequestStock from "./sideMenu/stockRequest/stockRequestSlice";
import LeaveApprove from "./sideMenu/leaveApprove/leaveApproveSlice";
import AssetRequest from "./sideMenu/assetRequest/assetRequestSlice";
import Visit from "./sideMenu/visit/visitSlice";
import ManualAttendance from "./sideMenu/manualAttendance/manualAttendanceSlice";
import VehicleLog from "./sideMenu/vehicleLog/vehicleLogSlice";
import CustomerLog from "./sideMenu/customerLog/customerLogSlice";
import IdealStock from "./sideMenu/idealStock/idealStockSlice";
import QR from "./sideMenu/QRCode/qrSlice";
import DailyPayment from "./sideMenu/dailyPayments/dailyPaymentSlice";
import Refurbished from "./sideMenu/Refurbished/refurbishedSlice";
import VehicleEstimation from "./sideMenu/vehicleEstimation/vehicleEstimationSlice";
import VehicleImage from "./sideMenu/vehicleImage/vehicleImageSlice";
import JobCard from "./sideMenu/jobCard/jobCardSlice";
import Material from "./sideMenu/material_issue/material_issueSlice";

export default {
  // Layout,
  customise: customiseReducer,

  // Authentication
  login: Login,

  //Notification
  notification: Notification,

  // mini
  mini: Mini,
  // Reports
  reports: Reports,
  // system APIs
  system: System,
  //Masters
  country: Country,
  district: District,
  states: State,
  city: City,
  area: Area,
  zones: Zones,
  firm: Firm,
  person: Person,
  brands: Brands,
  emissionnorms: Emissionnorms,
  occupations: Occupations,
  colors: Colors,
  banks: Banks,
  priority: Priority,
  companybanks: Companybanks,
  divisions: Divisions,
  clusters: Clusters,
  mechanics: Mechanics,
  dealers: Dealers,
  counters: Counters,
  formula: Formulas,
  departments: Departments,
  brandfamily: Brandfamily,
  models: Models,
  addressproof: Addressproof,
  emidates: Emidates,
  insurance: Insurance,
  leavereasons: Leavereasons,
  versionfeatures: Versionfeatures,
  rejectreasons: Rejectreasons,
  vehicletypes: Vehicletypes,
  showrooms: Showrooms,
  percentageinterests: Percentageinterests,
  financiers: Financiers,
  versions: Versions,
  vehicles: Vehicles,
  tenures: Tenures,
  salequotationlist: Salequotationlist,
  reEstimation: ReEstimation,
  repairAmount: RepairAmount,
  fvQuestion: FvQuestion,
  repairTypes: RepairTypes,
  bannersAds: BannersAds,
  asset: Asset,
  organization: Organization,
  category: Category,
  subCategory: SubCategory,
  items: Items,
  serviceMech: ServiceMech,
  ramp:Ramp,
  
  //userProfile
  userProfile: UserProfile,
  //userProfile

  //Settings
  manageUsers: ManageUsers,
  manageProfile: ManageProfile,
  locationManager: LocationManager,
  managers: Managers,
  approval: Approval,
  importExport: ImportExport,
  backupDatabase: BackupDatabase,
  manageCompany: ManageCompany,
  appSettings: AppSettings,

  //SideMenu
  purchase: Purchase,
  counterSales: CounterSales,
  enquiry: Enquiry,
  stockRequest: StockRequest,
  newInsurance: NewInsurance,
  rto: Rto,
  transactionApprove: TransactionApprove,
  stock: Stock,
  mystock: MyStock,
  stockPrice: StockPrice,
  target: Target,
  dashboard: Dashboard,
  customerDefaulter: CustomerDefaulter,
  requestStock: RequestStock,
  leaveApprove: LeaveApprove,
  assetRequest: AssetRequest,
  visit: Visit,
  manualAttendance: ManualAttendance,
  vehicleLog: VehicleLog,
  customerLog: CustomerLog,
  idealStock: IdealStock,
  qr: QR,
  dailyPayment: DailyPayment,
  refurbished: Refurbished,
  dailyExpense: DailyExpense,
  vehicleEstimation: VehicleEstimation,
  vehicleImage: VehicleImage,
  jobCard: JobCard,
  material: Material,
};
