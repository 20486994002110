
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
  } from "react-icons/ri";


export const PopMessage = (text, status) => {
  switch (status) {
    case "Success":
      return Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">{status}</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">{text}</p>
          </div>
        ),
      });
    case "Error":
      return Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">{status}</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {text}
            </p>
          </div>
        ),
      });
  }
};


