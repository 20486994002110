import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getVehicleImage,
  vehicleImgListSuccessful,
  isModelVisible,
} from "./vehicleImageSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData, 
} from "../../../helpers/Helper";



function* VehicleImgList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/attendance/vehicleimages", params);
    yield put(vehicleImgListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* AddVehicleImage({ payload }) {
  console.log("pddfysdf", payload)
  try {
    const response = yield call(postFormData, "/attendance/vehicleimages", payload);
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle Image Added Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getVehicleImage({ page: 1, search: "", page_size: 10 }));
      yield put(isModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Vehicle Image. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* DeleteVehicleImg({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, "/attendance/vehicleimages/" + areaID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle Image has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getVehicleImage({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your  Vehicle Image has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}


function* watchGetVehicleImage() {
  yield takeEvery("vehicleImage/getVehicleImage", VehicleImgList);
}
export function* watchVehicleImageAdd () {
  yield takeEvery("vehicleImage/vehicleImageAdd", AddVehicleImage);
}
export function* watchVehicleImgDelete() {
  yield takeEvery("vehicleImage/vehicleImgDelete", DeleteVehicleImg);
}
function* vehicleImageSaga() {
  yield all([
    fork(watchGetVehicleImage),
    fork(watchVehicleImageAdd),
    fork(watchVehicleImgDelete),
  ]);
}

export default vehicleImageSaga;
