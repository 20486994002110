import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  vehicleEstApprovedListSuccessful,
  getVehicleJobCardSuccessful,
  jobCardListSuccessful,
  getJobCardList,
  jobCardByIdSuccessful,
  addJobCardSuccessful,
  itemsListSuccessful,
  isMechanicModelVisible,
  updateJobCardMechanicSuccessful,
  updateJobCardSuccessful,
  setTimeStart,
  setEndTime
} from "./jobCardSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";




function* VehicleEstApprovedList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/vehicleestimationapproved/mini/", params);
    yield put(vehicleEstApprovedListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* VehicleJobCardGet({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/vehicleestimationbyvehicle/" + payload.id,
      {}
    );
    yield put(getVehicleJobCardSuccessful({ response}));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* JobCardList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/jobcard", params);
    yield put(jobCardListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* JobCardById({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/jobcard/" + payload,
      {}
    );
    yield put(jobCardByIdSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// old code


function* JobCardAdd({ payload }) {
  console.log("payload.history", payload.history)
  try {
    const response = yield call(postAdd, "/purchase/jobcard", payload.obj);
    if (response.status === 200 || response.status === 201) {
      // const formData = serialize(payload.profileObj);
      // const response2 = yield call(
      //   postEdit,
      //   "/purchase/jobcardimages/" + response.data?.id,
      //   formData
      // );
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Job Card Added Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getJobCardList({ page: 1, search: "", page_size: 10 }));
      yield put(addJobCardSuccessful())
      payload.history.push("/pages/job_card");
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to add Job Card. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* ItemsList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/item/", params);
    yield put(itemsListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* JobCardMechanicUpdate({payload}){
  try {
    const response = yield call(postEdit, "/purchase/jobcardmechanic/" + payload.id, payload);
    if(response.status === 200){
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Job Card Mechanic Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(setTimeStart({ isTimeActive: true, id: payload.id, }));
      yield put(isMechanicModelVisible(false))
      yield put(getJobCardList({ page: 1, search: "", page_size: 10 }));
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update Job Card Mechanic. Please try again!
            </p>
          </div>
        ),
      });
    }
  }catch(error){
    yield put(apiError(error));
  }
}


function* UpdateStatusJobCard({payload}){
  try {
    const response = yield call(postEdit, "/purchase/jobcardworkstatus/" + payload.id, payload);
    if(response.status === 200){
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Job Card Work Status Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(setTimeStart({ isTimeActive: false, id: payload.id, }));
      yield put(getJobCardList({ page: 1, search: "", page_size: 10 }));
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update Job Card Work Status. Please try again!
            </p>
          </div>
        ),
      });
    }
  }catch(error){
    yield put(apiError(error));
  }
}


function* JobCardUpdate({payload}){
  try {
    const response = yield call(postEdit, "/purchase/jobcard/" + payload.obj.id, payload.obj);
    if(response.status === 200){
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Job Card Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getJobCardList({ page: 1, search: "", page_size: 10 }));
      yield put(updateJobCardSuccessful())
      payload.history.push("/pages/job_card");
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update Job Card. Please try again!
            </p>
          </div>
        ),
      });
    }
  }catch(error){
    console.log("eeeee", error)
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue++++++++++++!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


export function* watchGetVehicleEstApproved() {
  yield takeEvery("jobCard/getVehicleEstApproved", VehicleEstApprovedList);
}
export function* watchGetVehicleJobCard() {
  yield takeEvery("jobCard/getVehicleJobCard", VehicleJobCardGet);
}
export function* watchGetJobCardList() {
  yield takeEvery("jobCard/getJobCardList", JobCardList);
}
export function* watchGetJobCardById() {
  yield takeEvery("jobCard/getJobCardById", JobCardById);
}

export function* watchAddJobCard() {
  yield takeEvery("jobCard/addJobCard", JobCardAdd);
}
export function* watchGetItemsList() {
  yield takeEvery("jobCard/getItemsList", ItemsList);
}
export function* watchUpdateJobCardMechanic() {
  yield takeEvery("jobCard/updateJobCardMechanic", JobCardMechanicUpdate);
}
export function* watchJobCardUpdateStatus() {
  yield takeEvery("jobCard/jobCardUpdateStatus", UpdateStatusJobCard);
}
export function* watchUpdateJobCard() {
  yield takeEvery("jobCard/UpdateJobCard", JobCardUpdate);
}
function* jobCardSaga() {
  yield all([
    fork(watchGetVehicleEstApproved),
    fork(watchGetVehicleJobCard),
    fork(watchGetJobCardList),
    fork(watchGetJobCardById),
    fork(watchAddJobCard),
    fork(watchGetItemsList),
    fork(watchUpdateJobCardMechanic),
    fork(watchJobCardUpdateStatus),
    fork(watchUpdateJobCard),
  ]);
}

export default jobCardSaga;
