import { createSlice } from "@reduxjs/toolkit";
import { acos } from "mathjs";
import moment from "moment";
export const material_issueSlice = createSlice({
  name: "material",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    
    listLoading: false,
    materialList: [],
    listCount: 0,
    materialParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    viewLoading: false,
    materialView: {},
    jobCardMiniList:[],
    jobCardMiniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    jobCardMiniLoad: false,
    materialData: {},
    materialItemsList: [],
    materialItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    materialItemCount: 0,
    materialItemLoading: false,
    selectedMaterialItemsList:[],
    addLoading: false,
    jobCardData: {},
    filterStatus: false,
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        listLoading: false,
        jobCardMiniLoad: false,
        materialItemCount: 0,
        materialData: {},
        materialList: [],
        materialParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        materialItemsList: [],
        materialItemsParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        jobCardData:{},
        selectedMaterialItemsList:[],
      };
    },

    
    getMaterialIssueList: (state, action) => {
      if (state.materialParams?.page == 1) {
        return {
          ...state,
          listLoading: true,
          jobCardList: [],
        };
      } else {
        return {
          ...state,
          listLoading: true,
        };
      }
    },
    materialIssueListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.materialParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.materialParams?.page_size
      );
      return {
        ...state,
        materialList: list,
        listCount: action.payload.count,
        listLoading: false,
        materialParams: {
          ...state.materialParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMaterialIssueParams: (state, action) => {
      return {
        ...state,
        materialParams: action.payload,
      };
    },

    getMaterialById: (state) => {
      return {
        ...state,
        viewLoading: true,
      };
    },
    materialByIdSuccessful: (state, action) => {
      return {
        ...state,
        viewLoading: false,
        materialView: action.payload.response,
      };
    },
    // get job card mini
    getJobCardMini: (state, action) => {
      if (state.jobCardMiniParams?.page == 1) {
        return {
          ...state,
          jobCardMiniLoad: true,
          jobCardMiniList: [],
        };
      } else {
        return {
          ...state,
          jobCardMiniLoad: true,
        };
      }
    },
    jobCardMiniListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.jobCardMiniParams?.page == 1) {
        list = response;
      } else {
        list = response;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.jobCardMiniParams?.page_size
      );
      return {
        ...state,
        jobCardMiniList: list,
        jobCardMiniLoad: false,
        jobCardMiniParams: {
          ...state.jobCardMiniParams,
          no_of_pages: noofpages,
        },
      };
    },
    setJobCardMiniParams: (state, action) => {
      return {
        ...state,
        jobCardMiniParams: action.payload,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        materialData: {
          ...state.materialData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getMaterialItems: (state, action) => {
      if(state.materialItemsParams?.page == 1){
        return {
          ...state,
          materialItemLoading: true,
          materialItemsList:[],
        }
      }else{
        return {
          ...state,
          materialItemLoading: true,
        }
      }
    },
    materialItemSuccessful: (state, action) => {
      console.log("materialItemSuccessful", action.payload)
      var jobCardDetails = action.payload
      var response = action.payload.jobcarditems;

      var list = response.map((item) => {
        // if (item.stc_item != null) {
        //   item.consumption_qty = item.stc_item?.qty;
        //   item.remarks = item.stc_item?.remarks;
        // }

        var filterlist = state.selectedMaterialItemsList.filter(
          (scItem) =>
            scItem.item?.id == item?.id 
        );
        // console.log("filterlist", filterlist);
        if (filterlist.length > 0) {
          item.qty = filterlist[0].qty;
          item.description = filterlist[0].description;
        }

        return {
          ...item,
        };
      });

      var noofpages = Math.ceil(
        action.payload.count / state.materialItemsParams?.page_size
      );
      return {
        ...state,
        materialItemsList: list,
        jobCardData: jobCardDetails,
        materialItemCount: action.payload.jobcarditems.length,
        materialItemLoading: false,
        materialItemsParams: {
          ...state.materialItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMaterialItemParams: (state, action) => {
      return {
        ...state,
        materialItemsParams: action.payload,
      };
    },
    ItemInputChangeValue: (state, action) => {
      var selectedStockItem = state.materialItemsList.map((item) => {
        if (
          item?.id == action.payload.item?.id
        ) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        materialItemsList: selectedStockItem,
      };
    },
    updateMaterialItemsList: (state, action) => {
      var filterlist = state.selectedMaterialItemsList.filter(
        (item) =>
          item?.id == action.payload.item?.id 
      );
      if (filterlist.length == 0) {
        return {
          ...state,
          selectedMaterialItemsList: [
            ...state.selectedMaterialItemsList,
            {
              ...action.payload.item,
              [action.payload.key]: action.payload.value,
            },
          ],
        };
      } else {
        var SelectedList = state.selectedMaterialItemsList.map((item) => {
          if (item?.id == action.payload.item?.id) {
            return {
              ...item,
              [action.payload.key]: action.payload.value,
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          selectedMaterialItemsList: SelectedList,
        };
      }
    },
    addMaterialIssue: (state, action) => {
      return {
        ...state,
        addLoading: true,
      }
    },
    addMaterialIssueSuccessful: (state, action) => {
      return {
        ...state,
        addLoading: false,
      }
    },
    isDrawerVisible:(state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        materialParams: {
          ...state.materialParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
   
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
    
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    }
   
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  getMaterialIssueList,
  materialIssueListSuccessful,
  setMaterialIssueParams,
  getMaterialById,
  materialByIdSuccessful,
  getJobCardMini,
  jobCardMiniListSuccessful,
  setJobCardMiniParams,
  InputChangeValue,
  getMaterialItems,
  materialItemSuccessful,
  setMaterialItemParams,
  ItemInputChangeValue,
  updateMaterialItemsList,
  addMaterialIssue,
  addMaterialIssueSuccessful,
  isDrawerVisible,
  FilterInputChangeValue,
  checkFilterStatus,
  apiError,
  
} = material_issueSlice.actions;

export default material_issueSlice.reducer;
