import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const vehicleImageSlice = createSlice({
  name: "vehicleImage",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    imageLooading: false,
    model: false,
    drawer: false,
    error_msg: null,
    listCount: 0,
    vehicleImgList: [],
    vehicleImgParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    rowdata: {},
    vehicleDetails: {},
    previewFile: {},
  },
  reducers: {
    clearVisitData: (state, action) => {
      return{
        ...state,
        loading: false,
        imageLooading: false,
        vehicleImgList: [],
        rowdata: {},
      }
    },
    getVehicleImage: (state, action) => {
      if (state.vehicleImgParams?.page == 1) {
        return {
          ...state,
          loading: true,
          vehicleImgList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    vehicleImgListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.vehicleImgParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.vehicleImgParams?.page_size
      );
      return {
        ...state,
        vehicleImgList: list,
        listCount: action.payload.count,
        loading: false,
        vehicleImgParams: {
          ...state.vehicleImgParams,
          no_of_pages: noofpages,
        },
      };
    },
    setVehicleImgParams: (state, action) => {
      return {
        ...state,
        vehicleImgParams: action.payload,
      };
    },

  
    getVehicleByID: (state, action) => {
      return {
        ...state,
        // loading: false,
        vehicleDetails: action.payload,
      };
    },

    addImageUpload: (state, action) => {
      return {
        ...state,
        // imageLooading: true,
        // vehicleImg: [...state.vehicleImg, action.payload],
        // estimationData: {...state.estimationData,   ...action.payload}
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: action.payload.file,
        }
      };
    },

    setPreviewImg: (state, action) => {
      return{
        ...state,
        // imageLooading: false,
        rowdata: {
          ...state.rowdata,
          previewFile: action.payload,
        }
      }
    },

    vehicleImageAdd : (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          vehicleDetails:{},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          vehicleDetails:{},
        };
      }
    },
    vehicleImgDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        vehicleImgParams: {
          ...state.vehicleImgParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getVehicleImage,
  vehicleImgListSuccessful,
  setVehicleImgParams,
  getVehicleByID,
  addImageUpload,
  setPreviewImg,
  vehicleImageAdd,
  vehicleImgDelete,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  clearVisitData,
  apiError,
} = vehicleImageSlice.actions;

export default vehicleImageSlice.reducer;
