import { createSlice } from "@reduxjs/toolkit";

export const rampSlice = createSlice({
  name: "ramp",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    rampList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    rampParams: {
      no_of_pages: 0,
     
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getRampList: (state, action) => {
      if (state.rampParams?.page == 1) {
        return {
          ...state,
          loading: true,
          rampList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
   rampListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.rampParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.rampParams?.page_size
      );
        return {
          ...state,
          rampList: list,
          listCount: action.payload.count,
          loading: false,
          rampParams: {
            ...state.rampParams,
            no_of_pages: noofpages,
          },
        };
    },
   rampAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    rampDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SeRampParams: (state, action) => {
      return {
        ...state,
        rampParams: action.payload,
      };
    },
    setSelectData: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          ...action.payload,
          id: action.payload.id,
          rampno: action.payload.rampno,
          counter_id: action.payload.counter?.id
        },
       
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRampList,
  rampListSuccessful,
  rampAdd,
  brandfamilyEdit,
  rampDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SeRampParams,
  setSelectData
} = rampSlice.actions;

export default rampSlice.reducer;
