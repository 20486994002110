import { createSlice } from "@reduxjs/toolkit";

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    subCategoryList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    subCategoryParams: {
      no_of_pages: 0,
     
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getSubCategory: (state, action) => {
      // return {
      //   ...state,
      //   loading: true,
      // };
      if (state.subCategoryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          subCategoryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
   subCategoryListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.subCategoryParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.subCategoryParams?.page_size
      );
        return {
          ...state,
          subCategoryList: list,
          listCount: action.payload.count,
          loading: false,
          subCategoryParams: {
            ...state.subCategoryParams,
            no_of_pages: noofpages,
          },
        };
    },
   subCategoryAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    subCategoryDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetSubCategoryParams: (state, action) => {
      return {
        ...state,
        subCategoryParams: action.payload,
      };
    },
    setSelectData: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          ...action.payload,
          id: action.payload.id,
          name: action.payload.name,
          category_id: action.payload.category?.id
        },
       
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSubCategory,
  subCategoryListSuccessful,
  subCategoryAdd,
  brandfamilyEdit,
  subCategoryDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetSubCategoryParams,
  setSelectData
} = subCategorySlice.actions;

export default subCategorySlice.reducer;
