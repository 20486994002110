import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import { browserName } from "react-device-detect";
// import { io } from "socket.io-client";

// const BaseURL = "https://wheelsmart.dev.absol.in"; //dev
// const IOBaseURL = "ws://144.24.97.26:5000"; // dev Io

// const BaseURL = "https://wheelsmart.staging.absol.in"; //Staging
// const IOBaseURL = "ws://144.24.97.26:5001"; // Test Io

// const BaseURL = "https://wheelsmart.testing.absol.in"; //Test
// const IOBaseURL = "ws://144.24.97.26:5001"; // Test Io

const BaseURL = "https://api.wheelsmart.in"; //Prod
// const IOBaseURL = "ws://144.24.97.26:5001"; // Test Io

// var BaseURL = process.env.REACT_APP_NOT_BASE_URL; //BaseURL
// var IOBaseURL = process.env.REACT_APP_NOT_IO_BASE_URL; // IOBaseURL
var Debug =
  process.env.REACT_APP_NOT_DEBUG === undefined
    ? false
    : process.env.REACT_APP_NOT_DEBUG === "true"
      ? true
      : false; // true or flase

console.log(
  "process.env.REACT_APP_NOT_BASE_URL",
  process.env.REACT_APP_NOT_BASE_URL
);
console.log(
  "process.env.REACT_APP_NOT_IO_BASE_URL",
  process.env.REACT_APP_NOT_IO_BASE_URL
);
console.log(
  "process.env.REACT_APP_NOT_DEBUG",
  process.env.REACT_APP_NOT_DEBUG,
  typeof Debug
);

const TOKEN_PREFIX = "Bearer  ";
const uuid = uuidv4();

// const socket = io.connect(IOBaseURL);
// console.log("socket connect====> ", socket);

// socket.on("connect", () => {
//   console.log("connect=================>");
//   if (localStorage.getItem("access_token") !== "") {
//     socket.emit("login", {
//       access_token: localStorage.getItem("access_token"),
//       device_uuid: localStorage.getItem("device_uuid"),
//     });
//   }
// });

// socket.on("disconnect", () => {
//   console.log("disconnect=================>");
// });

// socket.on("login_feedback", (msg) => {
//   console.log("login_feedback", msg);
// });

const axiosInstance = axios.create({
  baseURL: BaseURL,
  timeout: 120000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? TOKEN_PREFIX + localStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

function removeToken() {
  localStorage.clear();
  window.location.href = "/login/";
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken && refreshToken !== "undefined") {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return new Promise(function (resolve, reject) {
            axiosInstance
              .post(BaseURL + "/users/token/refresh/", {
                refresh: refreshToken,
                device_uuid: uuid,
                device_name: browserName,
                device_type: 3, // web
              })
              .then((response) => {
                localStorage.setItem("access_token", response.data.access);

                axiosInstance.defaults.headers["Authorization"] =
                  TOKEN_PREFIX + response.data.access;
                originalRequest.headers["Authorization"] =
                  TOKEN_PREFIX + response.data.access;
                processQueue(null, response.data.access);
                resolve(axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                // store.dispatch(showMessage({ message: "Expired Token" }));

                // window.location = "/login/";
                removeToken();

                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
          });
        } else {
          // window.location.href = "/login/";
          removeToken();
        }
      } else {
        // window.location.href = "/login/";
        removeToken();
      }
    } else if (err.response.status === 403) {
      if (!Debug) {
        window.location.href = "/pages/error-page-403";
      }
    } else if (err.response.status === 500) {
      if (!Debug) {
        window.location.href = "/pages/error-page-500";
      }
    } else if (err.response.status === 502) {
      if (!Debug) {
        window.location.href = "/pages/error-page-502";
      }
    } else if (err.response.status === 503) {
      if (!Debug) {
        window.location.href = "/pages/error-page-503";
      }
      // } else if (err.response.status === 524) {
      //   if (!Debug) {
      //     window.location.href = "/pages/error-page-503";
      //   }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
export {
  BaseURL,
  //  IOBaseURL, socket,
  axiosInstance,
  TOKEN_PREFIX,
};
