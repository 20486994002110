import { createSlice } from "@reduxjs/toolkit";
import { acos } from "mathjs";
import moment from "moment";
export const jobCardSlice = createSlice({
  name: "jobCard",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    expensesList: [],
    expensesParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    estimationData: {},
    vehicleVersion: {},

    itemsAddData: {},

    // item list
    itemsList: [],
    itemCount: 0,
    itemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    selectedRowKeys: [],

    previewFile: {},
    previewBackFile: {},
    ShowSubmit: false,
    categorys: [],
    seen_categorys: [],
    grand_total: 0,




    // new code
    estApprovedList: [],
    estApprovedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    approveLoading: false,
    approvedCount: 0,
    approveData: {},
    jobCardLoading: false,
    jobCardList: [],
    jobCardParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    jobCardView: {},
    jobCardAddLoading: false,
    newSelectedItems: [],

    mechanicModel: false,
    jobCardMechData: {},
    mechLoading: false,
    jobCardUpdateLoading: false,


    isTimeActive: false,
    activeJobCardId: null,
    filterStatus: false,
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        jobCardList: [],
        jobCardParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        selectedRowKeys: [],
        newSelectedItems: [],
        previewFile: {},
        previewBackFile: {},
        estimationData: {},
        vehicleVersion: {},
        ShowSubmit: false,
        jobCardUpdateLoading: false,
      };
    },


    // Estimation Approved List
    getVehicleEstApproved: (state, action) => {
      if (state.estApprovedParams?.page == 1) {
        return {
          ...state,
          approveLoading: true,
          estApprovedList: [],
        };
      } else {
        return {
          ...state,
          approveLoading: true,
        };
      }
    },
    vehicleEstApprovedListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.estApprovedParams?.page == 1) {
        list = response;
      } else {
        list = response;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.estApprovedParams?.page_size
      );
      return {
        ...state,
        estApprovedList: list,
        approvedCount: action.payload.count,
        approveLoading: false,
        estApprovedParams: {
          ...state.estApprovedParams,
          no_of_pages: noofpages,
        },
      };
    },
    setVehicleEstApprovedParams: (state, action) => {
      return {
        ...state,
        estApprovedParams: action.payload,
      };
    },
    ApproveInputChangeValue: (state, action) => {
      return {
        ...state,
        approveData: {
          ...state.approveData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getVehicleJobCard: (state, action) => {
      return {
        ...state,
        jobCardLoading: true,
      }
    },
    getVehicleJobCardSuccessful: (state, action) => {
      var response = action.payload.response.results[0];

      var itemsss = response.vestimationitems.map((item, i) => {
        return {
          _id: item.id,
          item_id: item.item?.id,
          name: item.item?.name,
          image: item.item?.image,
          category_id: item.category,
          subcategory_id: item.subcategory,
          price: item.price,
          new_price: item.price,
          quantity: item.quantity,
          description: item.description,
          damage_estimation: item.damage_estimation,
          category_price: item.category_price,
          dodelete: false,
          checkedItem: false,
        };
      });

      let arr = state.categorys.map(cat => {
        const categoryTotal = itemsss.reduce((total, item) => {
          if (item.category_id?.id === cat.id) {
            return total + parseFloat(item.price);
          }
          return total;
        }, 0);

        return {
          ...cat,
          total: cat.total + categoryTotal
        };
      });

      console.log(arr);


      return {
        ...state,
        jobCardLoading: false,
        estimationData: response,
        newSelectedItems: itemsss,
        grand_total: response.total_estimation,
        categorys: arr,
      }
    },


    // get Job Card List
    getJobCardList: (state, action) => {
      if (state.jobCardParams?.page == 1) {
        return {
          ...state,
          loading: true,
          jobCardList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    jobCardListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.jobCardParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.jobCardParams?.page_size
      );
      return {
        ...state,
        jobCardList: list,
        listCount: action.payload.count,
        loading: false,
        jobCardParams: {
          ...state.jobCardParams,
          no_of_pages: noofpages,
        },
      };
    },
    setJobCardParams: (state, action) => {
      return {
        ...state,
        jobCardParams: action.payload,
      };
    },


    getJobCardById: (state) => {
      return {
        ...state,
        loading: true,
        estimationData: {},

      };
    },
    jobCardByIdSuccessful: (state, action) => {
      var response = action.payload.response;
      var itemsss = response.jobcarditems.map((item, i) => {
        return {
          _id: item.id,
          jcItemId: item.id,
          id: item.item?.id,
          item_id: item.item?.id,
          name: item.item?.name,
          image: item.image,
          category_id: item.category,
          subcategory_id: item.subcategory,
          price: item.price,
          new_price: item.price,
          quantity: item.quantity,
          description: item.description,
          damage_estimation: item.damage_estimation,
          category_price: item.category_price,
          issued_qty: item.issued_qty,
          dodelete: false,
          checkedItem: false,
        };
      });

      return {
        ...state,
        jobCardLoading: false,
        loading: false,
        estimationData: response,
        newSelectedItems: itemsss,
        jobCardView: action.payload.response,
        grand_total: response.total_estimation
      }
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        jobCardParams: {
          ...state.jobCardParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    addJobCard: (state, action) => {
      return {
        ...state,
        jobCardAddLoading: true,
      };
    },
    addJobCardSuccessful: (state, action) => {
      return {
        ...state,
        jobCardAddLoading: false,
      };
    },

    UpdateJobCard: (state, action) => {
      return {
        ...state,
        jobCardUpdateLoading: true,
      };
    },
    updateJobCardSuccessful: (state, action) => {
      return {
        ...state,
        jobCardUpdateLoading: false,
        loading: false,
      };
    },

    setUploadImage: (state, action) => {
      return {
        ...state,
        estimationData: {
          file: action.payload.file,
          imageUrl: action.payload.imageUrl,
          loading: action.payload.loading
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          selectedRowKeys: [],
          model: action.payload,
        };
      } else {
        return {
          ...state,
          model: action.payload,
          selectedRowKeys: [],
          itemsList: [],
          itemsParams: {
            no_of_pages: 0,
            page_size: 20,
            page: 1,
            search: "",
          },
        };
      }
    },











    getItemsList: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
      };
    },
    itemsListSuccessful: (state, action) => {
      var response = action.payload;
      var newList = [];
      var selectedItemsList = state.newSelectedItems.filter(
        (item) => !item.dodelete
      );
      var selectItems_ids = selectedItemsList.map((i) => {
        return i._id;
      });
      newList = response.results.filter((item) => {
        return selectItems_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.itemsParams?.page == 1) {
        list = newList;
      } else {
        list = [...state.itemsList, ...newList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.itemsParams?.page_size
      );
      return {
        ...state,
        itemsList: list,
        loading: false,
        model: true,
        selectedRowKeys: [],
        itemsParams: {
          ...state.itemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetItemsParams: (state, action) => {
      return {
        ...state,
        itemsParams: action.payload,
      };
    },



    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleVersion: action.payload,
      };
    },



    setSelectedRow: (state, action) => {
      return {
        ...state,
        loading: false,
        // selectedRowKeys: [...state.selectedRowKeys, ...action.payload],
        selectedRowKeys: [...action.payload],
      };
    },

    addItem: (state, action) => {
      var itemsss = state.selectedRowKeys.map((item, i) => {
        return {
          _id: item.id,
          item_id: item.id,
          name: item.name,
          image: item.image,
          category_id: item.category,
          subcategory_id: item.subcategory,
          price: item.price,
          new_price: item.price,
          quantity: "",
          description: "",
          damage_estimation: "",
          category_price: "",
          dodelete: false,
          checkedItem: false,
        };
      });
      return {
        ...state,
        // newSelectedItems: [...state.newSelectedItems, ...itemsss],
        newSelectedItems: [...state.newSelectedItems, ...itemsss],
        selectedRowKeys: [],
        // selectedRowKeys: [
        //   ...state.selectedRowKeys,
        //   {
        //     _id: action.payload.id,
        //     item_id: action.payload.item?.id,
        //     category_id: action.payload.category?.id,
        //     subcategory_id: action.payload.subcategory?.id,
        //     quantity: "",
        //     price: "",
        //     description: "",
        //     damage_estimation: "",
        //     category_price: "",
        //     dodelete: false,
        //   },
        // ],
        model: false,
      };
    },

    removeItem: (state, action) => {
      var selectedRow = [];
      var category_id = action.payload.category_id.id
      if (
        action.payload.id == 0 ||
        action.payload.id == null ||
        action.payload.id == undefined
      ) {
        selectedRow = state.newSelectedItems.filter((item) => {
          return item._id != action.payload._id;
        });
      } else {
        selectedRow = state.newSelectedItems.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      }

      const cat_total = selectedRow?.filter((i) => category_id == i.category_id.id && !i.dodelete)?.reduce((total, i) => {
        return total + parseFloat(i.new_price)
      }, 0);

      var n_categorys = state.categorys.map((category) => {
        if (category.id == category_id) return { ...category, 'total': cat_total }
        return category
      })
      // console.log("n_categorys", n_categorys);
      const grand_total = n_categorys?.reduce((total, cat) => total + cat.total, 0);

      return {
        ...state,
        newSelectedItems: selectedRow,
        categorys: n_categorys,
        grand_total: grand_total
      };
    },

    SetCategorys: (state, action) => {
      var n_categorys = action.payload
      n_categorys = n_categorys.map((cat) => {
        return { ...cat, total: 0 }
      })
      // const grand_total = n_categorys?.reduce((total, cat) => total + cat.total, 0);
      return {
        ...state,
        categorys: n_categorys,
        // grand_total: grand_total
      };
    },
    ItemQtyChangeValue: (state, action) => {
      var newQuantity = action.payload.value;
      var qty = newQuantity && newQuantity != '' ? newQuantity : 0
      var row = action.payload.item;
      var selectedItem = state.newSelectedItems.map((item) => {
        if (item._id == action.payload.item._id) {
          return {
            ...item,
            'quantity': newQuantity,
            'new_price': qty ? qty * row.price : 0,
          };
        }
        return item;
      });

      const cat_total = selectedItem?.filter((i) => row.category_id.id == i.category_id.id && !i.dodelete)?.reduce((total, i) => {
        // console.log(total , parseFloat(i.new_price), total + parseFloat(i.new_price));
        return total + parseFloat(i.new_price)
      }, 0);

      var n_categorys = state.categorys.map((category) => {
        if (category.id == row.category_id.id) return { ...category, 'total': cat_total }
        return category
      })
      // console.log("n_categorys", n_categorys);
      const grand_total = n_categorys?.reduce((total, cat) => total + cat.total, 0);
      console.log("grand_total = ", grand_total)
      return {
        ...state,
        estimationData: {
          ...state.estimationData
        },
        newSelectedItems: selectedItem,
        categorys: n_categorys,
        grand_total: grand_total
      };
    },
    ItemInputChangeValue: (state, action) => {
      var selectedItem = state.newSelectedItems.map((item) => {
        if (item._id == action.payload.item._id) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        estimationData: {
          ...state.estimationData
        },
        newSelectedItems: selectedItem,
      };
    },

    addImageUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        // vehicleImg: [...state.vehicleImg, action.payload],
        // estimationData: {...state.estimationData,   ...action.payload}
        estimationData: {
          ...state.estimationData,
          [action.payload.imageType]: action.payload.file,
        }
      };
    },

    setPreviewImg: (state, action) => {
      return {
        ...state,
        loading: false,
        estimationData: {
          ...state.estimationData,
          previewFile: action.payload,
        }
      }
    },

    setPreviewBackImg: (state, action) => {
      return {
        ...state,
        loading: false,
        estimationData: {
          ...state.estimationData,
          previewBackFile: action.payload,
        }
      }
    },

    setShowSubmit: (state, action) => {
      // var is_check = state.seen_categorys.find(cat_id => cat_id == action.payload.cat_id )
      // var n_seen_categorys = []
      // if( !is_check ){
      //   n_seen_categorys = [ ...state.seen_categorys, action.payload.cat_id ]
      // } else {
      //   n_seen_categorys = state.seen_categorys
      // }

      var clearData = action.payload.clearData
      if (clearData) {
        return {
          ...state,
          seen_categorys: [],
          ShowSubmit: false
        };
      } else {
        var n_seen_categorys = Array.from(new Set([...state.seen_categorys, action.payload.cat_id]));
        var n_categorys_ids = Array.from(new Set(state.categorys.map(category => category.id)));
        // console.log("n_seen_categorys, n_categorys_ids, ", n_seen_categorys, n_categorys_ids, n_categorys_ids.length , n_seen_categorys.length , n_seen_categorys.length != 0 , ! clearData)
        return {
          ...state,
          seen_categorys: n_seen_categorys,
          ShowSubmit: n_categorys_ids.length == n_seen_categorys.length && n_seen_categorys.length != 0
        };
      }
    },


    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        estimationData: {
          ...state.estimationData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    isMechanicModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          mechanicModel: action.payload.status,
          jobCardMechData: {
            jobcard_id: action.payload.jobcard_id
          }
        };
      } else {
        return {
          ...state,
          mechanicModel: action.payload.status,
          jobCardMechData: {
            jobcard_id: action.payload.jobcard_id
          }
        };
      }
    },
    jobCardMechInputChangeValue: (state, action) => {
      return {
        ...state,
        jobCardMechData: {
          ...state.jobCardMechData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    jobCardUpdateStatus: (state, action) => {
      return {
        ...state,
      }
    },
    updateJobCardMechanic: (state, action) => {
      return {
        ...state,
        mechLoading: true,
        isTimeActive: true,
        activeJobCardId: action.payload.id,
      }
    },
    setTimeStart: (state, action) => {
      state.isTimeActive = action.payload;
      state.activeJobCardId = action.payload.id;
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  getVehicleEstApproved,
  vehicleEstApprovedListSuccessful,
  setVehicleEstApprovedParams,
  getVehicleJobCard,
  getVehicleJobCardSuccessful,
  ApproveInputChangeValue,
  getJobCardList,
  jobCardListSuccessful,
  setJobCardParams,
  getJobCardById,
  jobCardByIdSuccessful,
  isDrawerVisible,
  FilterInputChangeValue,
  ItemInputChangeValue,
  ItemQtyChangeValue,
  SetCategorys,

  addExpenseItem,
  isModelVisible,
  removeItem,
  addJobCard,
  addJobCardSuccessful,
  InputChangeValue,

  apiError,
  getVehicleVersion,
  getItemsList,
  itemsListSuccessful,
  SetItemsParams,
  setSelectedRowKeys,
  setSelectedRow,
  setUploadImage,
  addItem,
  addImageUpload,
  setPreviewImg,
  setPreviewBackImg,
  setShowSubmit,
  isMechanicModelVisible,
  jobCardMechInputChangeValue,
  updateJobCardMechanic,
  // updateJobCardMechanicSuccessful,
  jobCardUpdateStatus,
  UpdateJobCard,
  updateJobCardSuccessful,
  setTimeStart,
  checkFilterStatus,
} = jobCardSlice.actions;

export default jobCardSlice.reducer;
