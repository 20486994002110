import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getItemsList,
  itemsListSuccessful,
  isModelVisible,
  itemsModelListSuccessful,
  getItemsModelList,
  itemAddModel,
  ItemInputChangeValue,
  setSelectItem,
  updateIteModelPriceSuccessful,
  itemModelAddSuccessful,
  clearIteModelData,
} from "./itemSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

function* ItemsGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/item/", params);
    yield put(itemsListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddItem({ payload }) {
  try {
    if (payload.obj.id == 0 ||  payload.obj.id == null || payload.obj.id == undefined) {
      const response = yield call(postAdd, "/masters/item/", payload.obj);
      if (response.status === 201) {
        const formData = serialize(payload.profileObj);
        const response2 = yield call(
          postEdit,
          "/masters/itemimage/" + response.data?.id,
          formData
        );
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Item Add Successfully.!
              </p>
            </div>
          ),
        });
        yield put(isModelVisible(false));
        yield put(getItemsList({ page: 1, search: "", page_size: 10 }));
        if(response.data.id){
          
          yield put(getItemsModelList({ page: 1, search: "", page_size: 10, item: response.data.id }));
          yield put(setSelectItem(response.data))
          if(response.data.pricetype == 1){
            yield put(itemAddModel(false));
          }else{
            yield put(itemAddModel(true));
          }
        }
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Item. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(countryAddSuccessful(null));
    } else {
      const response = yield call(postEdit, "/masters/item/" + payload.obj.id, payload.obj);
      if (response.status === 200) {
        if(payload.profileObj){
          const formData = serialize(payload.profileObj);
          const response2 = yield call(postEdit,
             "/masters/itemimage/" + response.data?.id,
            formData
          );
        }
       
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Item Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getItemsList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));

       if(response.data.id){
          yield put(getItemsModelList({ page: 1, search: "", page_size: 10, item: response.data.id }));
          yield put(setSelectItem(response.data))
          if(response.data.pricetype == 1){
            yield put(itemAddModel(false));
          }else{
            yield put(itemAddModel(true));
          }
        }
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Item. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(countryAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteItem({ payload }) {
  try {
    const response = yield call(postDelete, "/masters/item/" + payload);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Item has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getItemsList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Item has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}


function* ItemModelList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/itemmodel/", params);
    yield put(itemsModelListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* AddItemModel({ payload }) {
  try {
      const response = yield call(postAdd, "/masters/itemmodelmulti/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Model is successfully tagged to this item!
              </p>
            </div>
          ),
        });
        yield put(getItemsModelList({ page: 1, search: "", page_size: 10, item: response.data?.[0].item_id }));
        yield put(itemModelAddSuccessful());
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to tagged to this model item!
              </p>
            </div>
          ),
        });
      }
  } catch (error) {
    if (error.response.status === 400) {
      yield put(clearIteModelData())
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.item[0].error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
      yield put(apiError(error));
    }
    yield put(apiError(error));
  }
}


function* DeleteItemModel({ payload }) {
  try {
    const response = yield call(postDelete, "/masters/itemmodel/" + payload.id);
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Item Model has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getItemsModelList({ page: 1, search: "", page_size: 10, item: payload.item_id }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Item modelvhas been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* ItemModelPriceUpdate({payload}){
  try {
    const response = yield call(
      postEdit,
      "/masters/itemmodel/" + payload.id,
      payload.obj
    );

    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Item Model Price has been Updated Successfully
            </p>
          </div>
        ),
      });
      yield put(getItemsModelList({ page: 1, search: "", page_size: 10, item: payload.obj.item_id }));
      yield put(updateIteModelPriceSuccessful())
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry Item Model Price has not been Update
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    if (error.response.status == 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
      yield put(apiError(error));
    }
    yield put(apiError(error));
    yield put(apiError(error));
  }
}

export function* watchGetItemsList() {
  yield takeEvery("items/getItemsList", ItemsGet);
}
export function* watchItemsAdd() {
  yield takeEvery("items/itemsAdd", AddItem);
}

export function* watchItemsDelete() {
  yield takeEvery("items/itemsDelete", DeleteItem);
}
export function* watchGetItemsModelList() {
  yield takeEvery("items/getItemsModelList", ItemModelList);
}
export function* watchItemModelAdd() {
  yield takeEvery("items/itemModelAdd", AddItemModel);
}
export function* watchItemModelDelete() {
  yield takeEvery("items/itemModelDelete", DeleteItemModel);
}
export function* watchUpdateItemModelPrice() {
  yield takeEvery("items/updateItemModelPrice", ItemModelPriceUpdate);
}
function* ItemSaga() {
  yield all([
    fork(watchGetItemsList),
    fork(watchItemsAdd),
    fork(watchItemsDelete),
    fork(watchGetItemsModelList),
    fork(watchItemModelAdd),
    fork(watchItemModelDelete),
    fork(watchUpdateItemModelPrice),
  ]);
}

export default ItemSaga;
