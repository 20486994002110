import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  materialIssueListSuccessful,
  materialByIdSuccessful,
  jobCardMiniListSuccessful,
  materialItemSuccessful,
  getMaterialIssueList,
  addMaterialIssueSuccessful,

} from "./material_issueSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

function* MaterialIssueList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/materialissue", params);
    yield put(materialIssueListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* MaterialById({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/materialissue/" + payload,
      {}
    );
    yield put(materialByIdSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniJobCardList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/jobcard/mini/", params);
    yield put(jobCardMiniListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MaterialItemsList({payload}) {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/jobcard/" + payload.id,
      {}
    );
    yield put(materialItemSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MaterialIssueAdd({ payload }) {
  console.log("MaterialIssueAdd", payload)
  try {
    const response = yield call(postAdd, "/purchase/materialissue", payload.obj);
    if (response.status === 200 || response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Material Issue Added Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getMaterialIssueList({ page: 1, search: "", page_size: 10 }));
      yield put(addMaterialIssueSuccessful())
      payload.history.push("/pages/material_issue");
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to add  Material Issue. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}







export function* watchGetMaterialIssueList() {
  yield takeEvery("material/getMaterialIssueList", MaterialIssueList);
}
export function* watchGetMaterialById() {
  yield takeEvery("material/getMaterialById", MaterialById);
}
export function* watchGetJobCardMini() {
  yield takeEvery("material/getJobCardMini", MiniJobCardList);
}
export function* watchGetMaterialItems() {
  yield takeEvery("material/getMaterialItems", MaterialItemsList);
}
export function* watchAddMaterialIssue() {
  yield takeEvery("material/addMaterialIssue", MaterialIssueAdd);
}



function* material_IssueSaga() {
  yield all([ 
    fork(watchGetMaterialIssueList),
    fork(watchGetMaterialById),
    fork(watchGetJobCardMini),
    fork(watchGetMaterialItems),
    fork(watchAddMaterialIssue),
  ]);
}

export default material_IssueSaga;
