import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/mobilelogin",
    component: lazy(() =>
      import("../../view/pages/authentication/login/mobilenumber-login")
    ),
    layout: "FullLayout",
  },
  {
    path: "/login",
    component: lazy(() =>
      import("../../view/pages/authentication/login/username-login")
    ),
    layout: "FullLayout",
  },
  // Master pages
  {
    path: "/Master",
    component: lazy(() => import("../../view/pages/MastersDashboard")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Locations",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/Locations")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/country",
    component: lazy(() => import("../../view/pages/MastersDashboard/country")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/state",
    component: lazy(() => import("../../view/pages/MastersDashboard/state")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/District",
    component: lazy(() => import("../../view/pages/MastersDashboard/district")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/City",
    component: lazy(() => import("../../view/pages/MastersDashboard/city")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Area",
    component: lazy(() => import("../../view/pages/MastersDashboard/area")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/zone",
    component: lazy(() => import("../../view/pages/MastersDashboard/zones")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/firm",
    component: lazy(() => import("../../view/pages/MastersDashboard/firm")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/brand",
    component: lazy(() => import("../../view/pages/MastersDashboard/brands")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/emissionnorms",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/emissionnorms")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/occupations",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/occupations")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/person",
    component: lazy(() => import("../../view/pages/MastersDashboard/person")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/colors",
    component: lazy(() => import("../../view/pages/MastersDashboard/colors")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/banks",
    component: lazy(() => import("../../view/pages/MastersDashboard/banks")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/priority",
    component: lazy(() => import("../../view/pages/MastersDashboard/priority")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/companybanks",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/companybanks")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/divisions",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/divisions")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/clusters",
    component: lazy(() => import("../../view/pages/MastersDashboard/clusters")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/mechanics",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/mechanics")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/dealers",
    component: lazy(() => import("../../view/pages/MastersDashboard/dealer")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Counters",
    component: lazy(() => import("../../view/pages/MastersDashboard/counters")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Formulas",
    component: lazy(() => import("../../view/pages/MastersDashboard/formulas")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Departments",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/departments")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/brandfamily",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/brandfamily")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/models",
    component: lazy(() => import("../../view/pages/MastersDashboard/models")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/addressproof",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/addressproof")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/emidates",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/emidates​")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/insurance",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/insurance")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/leavereasons",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/leavereasons")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/versionfeatures",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/versionfeatures")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/rejectreasons",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/rejectreasons")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/organization",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/hashCloudOrganization")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/vehicletypes",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/vehicletypes")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/showrooms",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/showrooms")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/percentageinterests",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/percentageinterests")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/tenures",
    component: lazy(() => import("../../view/pages/MastersDashboard/tenures")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/financiers",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/financiers")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/versions",
    component: lazy(() => import("../../view/pages/MastersDashboard/versions")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/vehicles",
    component: lazy(() => import("../../view/pages/MastersDashboard/vehicles")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Re-Estimation",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/reestimationamount")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Repair-Amount",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/repairamount")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Repair-Types",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/repairTypes")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/fv-questions",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/fvQuestions")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/banners-ads",
    component: lazy(() => import("../../view/pages/MastersDashboard/bannerAdds")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/assets",
    component: lazy(() => import("../../view/pages/MastersDashboard/assets")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/dailyExpenses",
    component: lazy(() => import("../../view/pages/MastersDashboard/dailyExpenses")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/category",
    component: lazy(() => import("../../view/pages/MastersDashboard/category")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/sub_category",
    component: lazy(() => import("../../view/pages/MastersDashboard/subCategory")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/items",
    component: lazy(() => import("../../view/pages/MastersDashboard/Items")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/service_mechanic",
    component: lazy(() => import("../../view/pages/MastersDashboard/serviceMechanic")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/ramp",
    component: lazy(() => import("../../view/pages/MastersDashboard/ramp")),
    layout: "VerticalLayout",
  },
// profile settings
  {
    path: "/pages/settings/",
    component: lazy(() => import("../../view/pages/settings")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/settings/user-details/:id",
    component: lazy(() =>
      import("../../view/pages/settings/ManageUsers/userDetails")
    ),
    layout: "VerticalLayout",
  },
  // {
  //   path: "/pages/settings/new-profile/:id",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/manage-profile",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/new-users/:id",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/managers",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/location-manager",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },

  // {
  //   path: "/pages/settings/approvals",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/app-settings",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/manage-company",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/screen-settings",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/backup-database",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/import-csv",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/export-csv",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/reset-database",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },

  // SideBar Pages
  {
    path: "/pages/purchases",
    component: lazy(() => import("../../view/pages/Sidemenu/purchases")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/purchasesadd/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/purchases/purchasesAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/purchases-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/purchases/purchaseDetailsView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/Vechicle-Sales",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/VehicleCounterSales")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicles-view/:id",
    component: lazy(() =>
      import(
        "../../view/pages/Sidemenu/VehicleCounterSales/VehicleSaleDetailsView"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/add-sales/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/VehicleCounterSales/AddSales")
    ),
    layout: "VerticalLayout",
  },
  // {
  //   path: "/pages/sales-quatation",
  //   component: lazy(() =>
  //     import("../../view/pages/Dashboard/VehicleCounterSales/SalesQuotation")
  //   ),
  //   layout: "VerticalLayout",
  // },
  {
    path: "/pages/sales-quatation-list",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/SaleQuotation/salequotationlist")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stockPrice",
    component: lazy(() => import("../../view/pages/Sidemenu/stockPriceView")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/mystock",
    component: lazy(() => import("../../view/pages/Sidemenu/myStock")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-view/:id",
    component: lazy(() => import("../../view/pages/Sidemenu/stock/stockView")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/mystock-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/myStock/myStockView")
    ),
    layout: "VerticalLayout",
  },

  {
    path: "/pages/stock",
    component: lazy(() => import("../../view/pages/Sidemenu/stock")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/stockPrice",
    component: lazy(() => import("../../view/pages/Reports/StockPrice/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stockRequest",
    component: lazy(() => import("../../view/pages/Sidemenu/stockRequest")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/leaveApprove",
    component: lazy(() => import("../../view/pages/Sidemenu/leaveApprove")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/assetRequest",
    component: lazy(() => import("../../view/pages/Sidemenu/assetReq/assetRequest")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/assetApproval",
    component: lazy(() => import("../../view/pages/Sidemenu/assetReq/assetApprovals")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/visit",
    component: lazy(() => import("../../view/pages/Sidemenu/visit")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/qrCode",
    component: lazy(() => import("../../view/pages/Sidemenu/QRCode")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/manualattendance",
    component: lazy(() => import("../../view/pages/Sidemenu/manualAttendance")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicle_Log",
    component: lazy(() => import("../../view/pages/Sidemenu/vehicleLog")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/customer_Log",
    component: lazy(() => import("../../view/pages/Sidemenu/customerLog")),
    layout: "VerticalLayout",
  },
  //TargetsView
  {
    path: "/pages/targets",
    component: lazy(() => import("../../view/pages/Sidemenu/targetsView")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/targets_achieve",
    component: lazy(() => import("../../view/pages/Sidemenu/targetAchieve")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/customer_defaulter",
    component: lazy(() => import("../../view/pages/Sidemenu/customerDefaulter")),
    layout: "VerticalLayout",
  },
  // errors
  {
    path: "/pages/error-page-404",
    component: lazy(() => import("../../view/pages/error/404/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-403",
    component: lazy(() => import("../../view/pages/error/403/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-500",
    component: lazy(() => import("../../view/pages/error/500/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-502",
    component: lazy(() => import("../../view/pages/error/502/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-503",
    component: lazy(() => import("../../view/pages/error/503/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/Maintenance",
    component: lazy(() => import("../../view/pages/error/maintenance/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/enquiry",
    component: lazy(() => import("../../view/pages/Sidemenu/enquiry")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-in-out",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/stocktransfer/stockInOut")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-transfer-approvals",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/stocktransfer/stockTransferApprovals")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-transfer-repair",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/stocktransfer/stockTransferRepairs")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-transfer-temprarory",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/stocktransfer/stockTransferTemprarory")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/new-insurance",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/insurance/newInsurance")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/approve-insurance",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/insurance/approveInsurace")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/insurer",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/insurance/insurer")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/rto",
    component: lazy(() => import("../../view/pages/Sidemenu/rto/newRto")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/transaction-approvals",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/transactionApprove")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/repairAmount-approvals",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/RepairAmountApprove")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/repairAmount-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/RepairAmountApprove")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/ideal-stock",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/idealStock")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/idealStock-Add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/idealStock/idealStockAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/idealStock-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/idealStock/idealStockView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dailyPayments",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/dailyPayments")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dailyPayments-add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/dailyPayments/dailyPaymentAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dailyPayments-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/dailyPayments/dailyPaymentView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/refurbished",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/refurbished")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicle_estimation",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/vehiclEstimation")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicleEstimation-add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/vehiclEstimation/vehicleEstimationAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicleEstimation-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/vehiclEstimation/vehicleEstimationView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicle_estimation_approve",
    component: lazy(() => import("../../view/pages/Sidemenu/vehicleServices/vehiclEstimationApprove")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/vehicle_Image",
    component: lazy(() => import("../../view/pages/Sidemenu/vehicleImages")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/job_card",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/JobCard")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/jobCard-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/JobCard/jobCardView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/jobCard-summary/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/JobCard/jobCardSummary")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/jobCard-add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/JobCard/jobCardAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/jobCard-edit/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/JobCard/jobCardEdit")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/material_issue",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/Material_Issue/Index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/material_add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/Material_Issue/MateriaIssueAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/material-view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/vehicleServices/Material_Issue/materiaIssueView")
    ),
    layout: "VerticalLayout",
  },
  // Profile View
  {
    path: "/pages/userProfile/personel-information",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/activity",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/password-change",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/user-devices",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  // REPORTS
  {
    path: "/pages/reports/",
    component: lazy(() => import("../../view/pages/Reports/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/DailyAttendance",
    component: lazy(() =>
      import("../../view/pages/Reports/DailyAttendance/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Sale",
    component: lazy(() => import("../../view/pages/Reports/Sale/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Purchase",
    component: lazy(() => import("../../view/pages/Reports/Purchase/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Stock",
    component: lazy(() => import("../../view/pages/Reports/Stock/index")),
    layout: "VerticalLayout",
  },

  {
    path: "/pages/report/RepairVechicle",
    component: lazy(() =>
      import("../../view/pages/Reports/RepairVechicle/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/TempraroryTransfer",
    component: lazy(() =>
      import("../../view/pages/Reports/TempraroryTransfer/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Enquiry",
    component: lazy(() => import("../../view/pages/Reports/Enquiry/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/SaleQuotation",
    component: lazy(() =>
      import("../../view/pages/Reports/SaleQuotation/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/RepairAmount",
    component: lazy(() =>
      import("../../view/pages/Reports/RepairAmount/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/RE-Amount",
    component: lazy(() => import("../../view/pages/Reports/REAmount/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Insurance",
    component: lazy(() => import("../../view/pages/Reports/Insurance/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Leave",
    component: lazy(() => import("../../view/pages/Reports/Leave/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/stock-transfer-out",
    component: lazy(() =>
      import("../../view/pages/Reports/StockTransferOut/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/stock-transfer-in",
    component: lazy(() =>
      import("../../view/pages/Reports/StockTransferIn/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Employee-Activity",
    component: lazy(() =>
      import("../../view/pages/Reports/EmployeeActivity/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/defaulter_Report",
    component: lazy(() =>
      import("../../view/pages/Reports/Defaulter/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/idealStock_Report",
    component: lazy(() =>
      import("../../view/pages/Reports/IdealStock/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/stock_idealStock_Report",
    component: lazy(() =>
      import("../../view/pages/Reports/Stock_IdealStock/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/SaleApprovals",
    component: lazy(() => import("../../view/pages/Reports/SaleApprovals/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/PurchaseApprovals",
    component: lazy(() => import("../../view/pages/Reports/PurchaseApprovals/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/estimation",
    component: lazy(() => import("../../view/pages/Reports/Estimation/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/refurbished",
    component: lazy(() => import("../../view/pages/Reports/Refurbished/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/sale_refurbished",
    component: lazy(() => import("../../view/pages/Reports/SaleRefurbished/index")),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
